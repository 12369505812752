import React, {Component} from 'react';
import {Auth} from 'aws-amplify'
import { Link } from 'react-router-dom';

import AuthImage from '../images/Pelham-Ape.png';
import AuthDecoration from '../images/auth-decoration.png';

export class Signin extends Component {
  constructor(props) {
    super(props)
    this._validAuthStates = ['signIn', 'signedOut', 'signedUp']
    this.signIn = this.signIn.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleFormSubmission = this.handleFormSubmission.bind(this)
    this.state = { isLoading: false }
  }

  handleFormSubmission(evt) {
    this.setState({
        isLoading: true
    })
    evt.preventDefault()
    this.signIn()
  }

  async signIn() {
    const username = this.inputs.username
    const password = this.inputs.password
    try {
      await Auth.signIn(username, password)
      this.props.onStateChange('signedIn', {})
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        this.props.updateUsername(username)
        await Auth.resendSignUp(username)
        this.props.onStateChange('confirmSignUp', {})
      } else if (err.code === 'NotAuthorizedException') {
        // The error happens when the incorrect password is provided
        this.setState({error: 'Login failed.'})
      } else if (err.code === 'UserNotFoundException') {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
        this.setState({error: 'Login failed.'})
      } else {
        this.setState({error: 'An error has occurred.'})
        console.error(err)
      }
    }
  }

  handleInputChange(evt) {
    this.inputs = this.inputs || {}
    const {name, value, type, checked} = evt.target
    const check_type = ['radio', 'checkbox'].includes(type)
    this.inputs[name] = check_type ? checked : value
    this.inputs['checkedValue'] = check_type ? value : null
    this.setState({error: ''})
  }
  render() {
    if (this.props.authState !== 'signedIn') {
      return (
        <main className="bg-white">

          <div className="relative md:flex">

            {/* Content */}
            <div className="md:w-1/2">
              <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">

                <div className="w-full">
                  <h1 className="text-3xl text-gray-800 font-bold mb-6">Pelham Portal ✨</h1>
                  {/* Form */}
                  <form>
                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium mb-1" htmlFor="email">Email Address</label>
                        <input id="username" name="username" className="form-input w-full" type="email" onChange={this.handleInputChange} />
                      </div>
                      <div>
                        <label className="block text-sm font-medium mb-1" htmlFor="password">Password</label>
                        <input id="password" name="password" className="form-input w-full" type="password" autoComplete="on" onChange={this.handleInputChange} />
                      </div>
                    </div>
                    <div className="flex items-center justify-between mt-6">
                      <div className="mr-1">
                        {/* <Link className="text-sm underline hover:no-underline" to="/reset-password">Forgot Password?</Link> */}
                      </div>
                      <Link className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3" to="/" onClick={this.signIn}>Sign In</Link>
                    </div>
                  </form>
                  {/* Footer */}
                  <div className="pt-5 mt-6 border-t border-gray-200">
                    <div className="text-sm">
                      For internal use only.
                    </div>
                    {/* <div className="text-sm">
                      Don’t you have an account? <Link className="font-medium text-indigo-500 hover:text-indigo-600" to="/signup">Sign Up</Link>
                    </div> */}
                    {/* Warning */}
                    {/* <div className="mt-5">
                      <div className="bg-yellow-100 text-yellow-600 px-3 py-2 rounded">
                        <svg className="inline w-3 h-3 flex-shrink-0 fill-current mr-2" viewBox="0 0 12 12">
                          <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                        </svg>
                        <span className="text-sm">
                          To support you during the pandemic super pro features are free until March 31st.
                        </span>
                      </div>
                    </div> */}
                  </div>
                </div>

              </div>
            </div>

            {/* Image */}
            <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
              <img className="object-cover object-center w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
              <img className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8" src={AuthDecoration} width="218" height="224" alt="Authentication decoration" />
            </div>

          </div>

        </main>
      );
    }
    else {
      return null
    }
  }
}

export default Signin;