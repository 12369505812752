/* src/App.js */
import React, { Component } from 'react'

// Amplify libraries
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports'

// Extensions
import App from '../App'

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

export class AppEntry extends Component {

  render() {
    if (this.props.authState === 'signedIn') {
      return (
        <div style={{ textAlign: "center" }}>
            <App />
        </div>
      )
    }
    else {
      return null
    }
  }
}