/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getKeys = /* GraphQL */ `
  query GetKeys($id: ID!) {
    getKeys(id: $id) {
      id
      service
      key
      secret
      createdAt
      updatedAt
    }
  }
`;
export const listKeyss = /* GraphQL */ `
  query ListKeyss(
    $filter: ModelKeysFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKeyss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        service
        key
        secret
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBots = /* GraphQL */ `
  query GetBots($id: ID!) {
    getBots(id: $id) {
      id
      name
      creator
      collection
      collectionSize
      offerPrice
      relistPrice
      expTime
      floorProtection
      active
      buynowonly
      createdAt
      updatedAt
    }
  }
`;
export const listBotss = /* GraphQL */ `
  query ListBotss(
    $filter: ModelBotsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBotss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        creator
        collection
        collectionSize
        offerPrice
        relistPrice
        expTime
        floorProtection
        active
        buynowonly
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
