import React from 'react';

import { ConnectWalletSign } from '../functions/connect-wallet-sign'

function ConnectWalletButton({
  align
}) {

  return (
    <div className="relative inline-flex">
      <button
        className={`w-8 h-8 flex items-center justify-center bg-gray-100 hover:bg-gray-200 transition duration-150 rounded-full`}
        aria-haspopup="true"
        onClick={ConnectWalletSign}
      >
        <span className="sr-only">Connect Wallet</span>
        <svg className="w-4 h-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path className="fill-current text-gray-500" d="M8,12L3.1,9.1L8,16l4.9-6.9L8,12L8,12z M8,0L3.1,8.1L8,11.1l4.9-2.9L8,0z" />
        </svg>
      </button>
    </div>
  )
}

export default ConnectWalletButton;