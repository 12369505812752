import { API, graphqlOperation } from 'aws-amplify'
import { listKeyss } from '../graphql/queries'
import { OpenSeaPort, Network } from 'opensea-js'

// functions
import { ConnectWalletSign } from './connect-wallet-sign'

const fetch = require('node-fetch')
var utils_1 = require("../../node_modules/opensea-js/lib/utils/utils");

export const cancelBot = async (botToCancel) => {
  var orders_cancelled = false
  try {

    // Ensure wallet is connected
    const wallet = await ConnectWalletSign()
    if (wallet.connectedStatus === true) {
      window.alert("You don't have a wallet connected, so the transaction cannot proceed.")
    }

    // Get opensea API key
    var key = {}
    const KEYS = await API.graphql(graphqlOperation(listKeyss))
    for (var i = 0; i < KEYS.data.listKeyss.items.length; i++) {
      key[KEYS.data.listKeyss.items[i].service] = KEYS.data.listKeyss.items[i].key
    }

    // Get bot collection slug
    const collection = botToCancel.collection

    // Get all orders in collection
    var orders = []
    if (collection === '') {
      throw new Error('No collection found!')
    }


    // const collection_url = 'https://api.opensea.io/api/v1/assets?order_direction=desc&offset=0&limit=1&collection=' + collection;
    // const collection_options = {method: 'GET'};

    // const collections_temp = await fetch(collection_url, collection_options)
      // .then(res => res.json())
      // .then(json => contractAddress = json.assets[0].asset_contract.address)
      // .catch(err => console.error('error:' + err));

    // contractAddress = collections_temp.assets[0].asset_contract.address

    // var token_ids = ''

    var offset = 0
    while (true) {

      // for (i = offset; i < offset + 1; i++) {
      //   token_ids += 'token_ids=' + i.toString() + '&'
      // }

      const url = 'https://api.opensea.io/wyvern/v1/orders?maker=' + wallet + '&bundled=false&include_bundled=false&include_invalid=false&limit=50&offset=' + offset + '&order_by=created_date&order_direction=desc';
      
      // TODO: cancel orders by collection/bot, not all from a given wallet; possibly by editing limit prop?
      // const url = 'https://api.opensea.io/wyvern/v1/orders?asset_contract_address=' + contractAddress + '&maker=' + wallet + '&bundled=false&include_bundled=false&include_invalid=false&' + token_ids + 'limit=50&offset=' + offset + '&order_by=created_date&order_direction=desc';
      const options = {method: 'GET', headers: {Accept: 'application/json'}};

      const currentOrderListSize = orders.length

      const orders_temp = await fetch(url, options)
        .then(res => res.json())

      orders.push(orders_temp.orders);

      if (orders.length !== (currentOrderListSize + 50)) {
        break;
      }

      offset += 50
    }
    orders = orders[0]

    if (window.confirm(orders.length + ' offers found! Are you sure you want to cancel them?')) {

        // Establish SDK connection to OpenSea
        const provider = window.ethereum
        const seaport = new OpenSeaPort(provider, {
          networkName: Network.Main
        })
    
        // Make offerPrice on all assets in collection
        for (var next_order = 0; next_order < orders.length; next_order++) {

          const order_to_cancel = utils_1.orderFromJSON(orders[next_order])
    
          await seaport.cancelOrder({
            accountAddress: wallet,
            order: order_to_cancel,
          })
          .catch(err => console.error('error: ', err));
        }
        orders_cancelled = true
        return orders_cancelled
      }
    } catch (error) {window.alert(error)}
}