import React from 'react'
import {Authenticator} from 'aws-amplify-react'
import Amplify from 'aws-amplify'
import awsconfig from '../aws-exports'
import AuthWrapper from './AuthWrapper'

Amplify.configure(awsconfig)

function Portal() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <header className="flex flex-col min-h-screen overflow-hidden">
        <Authenticator hideDefault={true} amplifyConfig={awsconfig}>
          <AuthWrapper />
        </Authenticator>
      </header>
    </div>
  )
}

export default Portal