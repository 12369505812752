import { API, graphqlOperation } from 'aws-amplify'
import { listKeyss } from '../graphql/queries'
import { OpenSeaPort, Network } from 'opensea-js'

// functions
import { ConnectWalletSign } from './connect-wallet-sign'

// API
import { createBots } from '../graphql/mutations'
const fetch = require('node-fetch')

// wallet
const MnemonicWalletSubprovider = require("@0x/subproviders")
  .MnemonicWalletSubprovider;
const RPCSubprovider = require("web3-provider-engine/subproviders/rpc");
const Web3ProviderEngine = require("web3-provider-engine");

export const createBot = async  (bot, mnemonic) => {

  // Ensure wallet is connected
  var userWallet = ''
  try{
    const wallet = await ConnectWalletSign()
    if (wallet.connectedStatus === true) {
      window.alert("You don't have a wallet connected, so the transaction cannot proceed.")
    }
    userWallet = wallet
  } catch(error) {
    console.log(error)
  }

  // Get opensea API key
  var key = {}
  try {
    const KEYS = await API.graphql(graphqlOperation(listKeyss))
    for (var i = 0; i < KEYS.data.listKeyss.items.length; i++) {
      key[KEYS.data.listKeyss.items[i].service] = KEYS.data.listKeyss.items[i].key
    }
  } catch (error) {
    console.log(error)
  }

  // Get all assets in collection
  var collectionPull = []
  try{

    var offset = 0
    while (true) {

      const url = 'https://api.opensea.io/api/v1/assets?order_direction=desc&offset=' + offset + '&limit=50&collection=' + bot.collection;

      const options = {method: 'GET', headers: {'X-API-KEY': key['openSeaAPI']}};

      const currentAssetListSize = collectionPull.length

      await fetch(url, options)
        .then(res => res.json())
        .then(json => collectionPull.push(...json.assets))
        if (collectionPull.length !== (currentAssetListSize + 50)) {
          break;
        }
        offset += 50
      }

      var collection = collectionPull
      if (bot.buynowonly) {
        var buyItNowCollection = []
        for (i = 0; i < collection.length; i++) {
          if (collection[i].sell_orders !== null) {
            buyItNowCollection.push(collection[i])
          }
        }

        collection = buyItNowCollection
      }

      if (collection.length === 0) {
        throw new Error('No assets found in the collection, please check your slug.')
      }

      // Final confirmation before placing orders
      if (window.confirm(collection.length + ' assets found! This bot will take approximately: ' + Math.ceil(collection.length / 30) + ' minutes to complete, and you must keep your computer on and this tab open until the bot completes. Are you sure you want to pay .01 ETH and place your bids?')) {
        try {
          bot.collectionSize = collection.length
          await API.graphql(graphqlOperation(createBots, {input: bot}))
          const expTimeHours = bot.expTime
          bot.expTime = Math.round(Date.now() + (1000*3600*bot.expTime)).toString()
          bot.active = true
          // var relistPrice = bot.relistPrice
      
          if (bot.offerPrice === '' || bot.expTime === '') {
            throw new Error('You must enter in an offer price and expiration time!')
          }

          const BASE_DERIVATION_PATH = `44'/60'/0'/0`;

          const mnemonicWalletSubprovider = new MnemonicWalletSubprovider({
            mnemonic: mnemonic,
            baseDerivationPath: BASE_DERIVATION_PATH,
          });

          const network = "mainnet";
          const infuraRpcSubprovider = new RPCSubprovider({
            rpcUrl: "https://" + network + ".infura.io/v3/" + key['infuraAPI']
          });

          const providerEngine = new Web3ProviderEngine();
          providerEngine.addProvider(mnemonicWalletSubprovider);
          providerEngine.addProvider(infuraRpcSubprovider);
          providerEngine.start();

          const seaport = new OpenSeaPort(
            providerEngine,
            {
              networkName: Network.Main,
              apiKey: key['openSeaAPI'],
            },
            // (arg) => console.log(arg)
          );
      
          // Make offerPrice on all assets in collection
          for (var asset = 0; asset < collection.length; asset++) {
            var offersMade = asset + 1
            const tokenAddress = collection[asset].asset_contract.address
            const tokenId = collection[asset].token_id
            
            // The offerer's wallet address:
            const accountAddress = userWallet
      
            await seaport.createBuyOrder({
              asset: {
                tokenId,
                tokenAddress,
              },
              accountAddress,
              expirationTime: Math.round(Date.now() / 1000 + 60 * 60 * expTimeHours),
              // Value of the offer, in units of the payment token (or wrapped ETH if none is specified):
              startAmount: bot.offerPrice,
            })
            .catch(err => console.error('Error: ', err))
            console.log('Last successful offer: ' + offersMade)
            // Listener
            // const testListener = seaport.addListener(EventType.TransactionCreated, ({ transactionHash, event }) => {
            //   console.info({ transactionHash, event })
            // })
          }
          
          

          window.alert("All offers made!")
          return collection.length
        } catch (error) {
          window.alert(error)
        }
      } 
      else {
        window.alert('Bot creation cancelled. Please make your changes and retry.')
      }
    } catch (error) {window.alert(error)}
}