/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createKeys = /* GraphQL */ `
  mutation CreateKeys(
    $input: CreateKeysInput!
    $condition: ModelKeysConditionInput
  ) {
    createKeys(input: $input, condition: $condition) {
      id
      service
      key
      secret
      createdAt
      updatedAt
    }
  }
`;
export const updateKeys = /* GraphQL */ `
  mutation UpdateKeys(
    $input: UpdateKeysInput!
    $condition: ModelKeysConditionInput
  ) {
    updateKeys(input: $input, condition: $condition) {
      id
      service
      key
      secret
      createdAt
      updatedAt
    }
  }
`;
export const deleteKeys = /* GraphQL */ `
  mutation DeleteKeys(
    $input: DeleteKeysInput!
    $condition: ModelKeysConditionInput
  ) {
    deleteKeys(input: $input, condition: $condition) {
      id
      service
      key
      secret
      createdAt
      updatedAt
    }
  }
`;
export const createBots = /* GraphQL */ `
  mutation CreateBots(
    $input: CreateBotsInput!
    $condition: ModelBotsConditionInput
  ) {
    createBots(input: $input, condition: $condition) {
      id
      name
      creator
      collection
      collectionSize
      offerPrice
      relistPrice
      expTime
      floorProtection
      active
      buynowonly
      createdAt
      updatedAt
    }
  }
`;
export const updateBots = /* GraphQL */ `
  mutation UpdateBots(
    $input: UpdateBotsInput!
    $condition: ModelBotsConditionInput
  ) {
    updateBots(input: $input, condition: $condition) {
      id
      name
      creator
      collection
      collectionSize
      offerPrice
      relistPrice
      expTime
      floorProtection
      active
      buynowonly
      createdAt
      updatedAt
    }
  }
`;
export const deleteBots = /* GraphQL */ `
  mutation DeleteBots(
    $input: DeleteBotsInput!
    $condition: ModelBotsConditionInput
  ) {
    deleteBots(input: $input, condition: $condition) {
      id
      name
      creator
      collection
      collectionSize
      offerPrice
      relistPrice
      expTime
      floorProtection
      active
      buynowonly
      createdAt
      updatedAt
    }
  }
`;
