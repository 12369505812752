import React, { useState } from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { listBotss } from '../graphql/queries'
import { updateBots } from '../graphql/mutations'

// UI
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
// import SearchForm from '../partials/actions/SearchForm';
// import FilterButton from '../components/DropdownFilter';
import CampaignsCard from '../partials/campaigns/CampaignsCard';
import ModalBasic from '../components/ModalBasic'
import Pelham from '../images/Pelham-round.png';

// Functions
import { createBot } from '../functions/create-bot'
import { cancelBot } from '../functions/cancel-bot'
// import { handleSeaportEvents } from '../functions/transaction-listener'

function Bots() {


  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [createBotModalOpen, setCreateBotModalOpen] = useState(false)
  const [cancelBotModalOpen, setCancelBotModalOpen] = useState(false)
  const [botRunning, setBotRunning] = useState(false)
  const [botNameToCancel, setBotNameToCancel] = useState('')
  const [bot, setBot] = useState(
    {
      name: '',
      creator: '',
      active: '',
      collection: '',
      collectionSize: '',
      offerPrice: '',
      relistPrice: '',
      expTime: '',
      floorProtection: false,
      buynowonly: false,
    }
  )
  const [mnemonic, setMnemonic] = useState('')
  const [activeBots, setActiveBots] = useState([{}])

  const runQuery = async () => {
    try {
      const user = await Auth.currentUserInfo()
      const queryData = await API.graphql(
        graphqlOperation(listBotss, {filter: {creator: { eq: user.attributes.email }}})
      );
      queryData.data.listBotss.items.sort(function(a, b) {
        var textA = a.createdAt;
        var textB = b.createdAt;
        return (textA < textB) ? 1 : (textA > textB) ? -1 : 0;
      });
      var active_bots = queryData.data.listBotss.items
      for (var bots in active_bots) {
        if (parseInt(active_bots[bots].expTime) < Date.now()) {
          active_bots.active = false
          var bot_update = {}
          bot_update.id = active_bots[bots].id
          bot_update.active = false
          await API.graphql(graphqlOperation(updateBots, {input: bot_update}))
        }
      }
      setActiveBots(active_bots) 
    } catch (err) {
      console.log("Error: ", err); 
    }
  };

  const runBotCreator = async () => {
    
    try {
      if (window.confirm('We will now search for all assets in the collection you requested. This can take some time for larger collections (up to 20 minutes for 10,000 asset collections), and if you close your browser, you will interrupt the process.')) {
        const user = await Auth.currentUserInfo()
        bot.creator = user.attributes.email
        if (bot.collection === '' || 
            bot.name === '' ||
            bot.offerPrice === '' ||
            bot.expTime === '') {
          throw new Error('You must fill in all required fields')
        }

        // Check relist price > offer price
        if (parseFloat(bot.offerPrice) >= parseFloat(bot.relistPrice)) {
          throw new Error('Your relist price must be greater than your offer price!')
        }

        for (var bots in activeBots) {
          if (activeBots[bots].name === bot.name) {
            throw new Error('You cannot duplicate a previously used bot name')
          }
        }

        bot.collectionSize = await createBot(bot, mnemonic)

        setCreateBotModalOpen(false)

        setBot({
          name: '',
          collection: '',
          offerPrice: '',
          relistPrice: '',
          expTime: '',
          floorProtection: false,
        })
        
        if (!bot.collectionSize) {
          throw new Error('Bot creation did not complete.')
        }

        runQuery()
      }
    } catch (error) {
      window.alert(error)
    }
    setBotRunning(false)
  };

  const runBotCanceller = async () => {
    var botToCancel = await API.graphql(graphqlOperation(listBotss, {filter: {name: { eq: botNameToCancel }}}))
    botToCancel = botToCancel.data.listBotss.items[0]
    
    try {
      if (botToCancel.active === false) {
        throw new Error("No active bots with that name!")
      }
      await cancelBot(botToCancel)
      var bot_update = {}
      bot_update.id = botToCancel.id
      bot_update.active = false
      console.log(bot_update)
      await API.graphql(graphqlOperation(updateBots, {input: bot_update}))
      window.alert("Bot cancelled!")
    } catch (error) {
      window.alert(error)
    }
    
    setBotRunning(false)
    setCancelBotModalOpen(false)
    runQuery()
  }

  React.useEffect(() => {
    runQuery();
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */} 
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">

              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Bots ✨</h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Search form */}
                {/* <SearchForm /> */}
                {/* Filter button */}
                {/* <FilterButton align="right" /> */}
                {/* Create campaign button */}
                <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white" onClick={(e) => { e.stopPropagation(); setCreateBotModalOpen(true); }}>
                  <svg className="w-4 h-4 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="hidden xs:block ml-2">Create Bot</span>
                </button>
                {/* <button className="btn bg-red-500 hover:bg-red-600 text-white" onClick={(e) => { e.stopPropagation(); setCancelBotModalOpen(true); }}>
                  <span className="hidden xs:block ml-2">Cancel Bot</span>
                </button> */}
              </div>

            </div>

            {/* CreateBot */}
            <div className="m-1.5" style={{ textAlign: "left" }}>
              {/* Start */}
              {/* <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white" aria-controls="feedback-modal" onClick={(e) => { e.stopPropagation(); setBotModalOpen(true); }}>Send Feedback</button> */}
              {/* Create bot modal */}
              <ModalBasic id="feedback-modal" modalOpen={createBotModalOpen} setModalOpen={setCreateBotModalOpen} title="Create Bot">
                {/* Modal content */}
                <div className="px-5 py-4">
                  <div className="space-y-3">
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="collection-name">Bot Name <span className="text-red-500">*</span></label>
                      <input id="bot-name" className="form-input w-full px-2 py-1" type="text" onChange={(e) => { setBot(bot => ({...bot, name: e.target.value })) }} value={bot.name}required />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="collection-name">Collection Name (slug) <span className="text-red-500">*</span></label>
                      <input id="collection-name" className="form-input w-full px-2 py-1" type="text" onChange={(e) => { setBot(bot => ({...bot, collection: e.target.value })) }} value={bot.collection}required />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="offer-price">Offer Price (WETH) <span className="text-red-500">*</span></label>
                      <input id="offer-price" className="form-input w-full px-2 py-1" type="text" onChange={(e) => { setBot(bot => ({...bot, offerPrice: e.target.value })) }} value={bot.offerPrice} required />
                    </div>
                    {/* <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="relist-price">Relist Price (WETH) <br></br> *Warning - in development, not currently functioning* </label>
                      <input id="relist-price" className="form-input w-full px-2 py-1" type="text" onChange={(e) => { setBot(bot => ({...bot, relistPrice: e.target.value })) }} value={bot.relistPrice} required />
                    </div> */}
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="expiration-time">Expiration Time (hours) <span className="text-red-500">*  <br></br> * Warning - monitor your bot closely, and empty your bot wallet if you want to 'cancel' your offers *</span></label>
                      <input id="expiration-time" className="form-input w-full px-2 py-1" type="text" onChange={(e) => { setBot(bot => ({...bot, expTime: e.target.value })) }} value={bot.expTime} required />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="buynowonly">Buy Now Only <span className="text-red-500"></span></label>
                      <input id="buynowonly" className="form-input checkbox" type="checkbox" onChange={(e) => { setBot(bot => ({...bot, buynowonly: e.target.checked })) }} value={bot.buynowonly} required />
                    </div>
                    {/* <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="floor-protection">Floor Protection <br></br> *Warning - in development, not currently functioning* </label>
                      <input id="floor-protection" className="form-input w-full px-2 py-1" type="checkbox" class="form-checkbox" onChange={(e) => { setBot(bot => ({...bot, floorProtection: e.target.checked })) }} checked={bot.floorProtection} required />
                    </div> */}
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="mnemonic">Mnemonic - required to avoid signing each transaction <span className="text-red-500"><br></br> * Warning - while our system is secure, sharing your mnemonic is always a risk, and Pelham accepts no liability for any breach resulting in initiating this bot *</span></label>
                      <input id="expiration-time" className="form-input w-full px-2 py-1" type="text" onChange={(e) => { setMnemonic(e.target.value ) }} value={mnemonic} required />
                    </div>
                  </div>
                </div>
                {/* Modal footer */}
                <div className="px-5 py-4 border-t border-gray-200">
                  <div className="flex flex-wrap justify-end space-x-2">
                    <button className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600" onClick={(e) => { e.stopPropagation(); setCreateBotModalOpen(false); }}>Cancel</button>
                    <button className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white" onClick={() => {setBotRunning(true); runBotCreator()}}>
                      {botRunning ? <svg className="animate-spin w-4 h-4 fill-current flex-shrink-0" viewBox="0 0 16 16">
                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                      </svg> : ''}
                      <span className="ml-2">Initiate Bot</span>
                    </button>
                  </div>
                </div>
              </ModalBasic>
              {/* Cancel bot modal */}
              <ModalBasic id="feedback-modal" modalOpen={cancelBotModalOpen} setModalOpen={setCancelBotModalOpen} title="Cancel Bot">
                {/* Modal content */}
                <div className="px-5 py-4">
                  <div className="space-y-3">
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="bot-name">Bot Name <br></br> * Important - you will pay gas for each early cancelation! *<span className="text-red-500"></span></label>
                      <input id="bot-name" className="form-input w-full px-2 py-1" type="text" onChange={(e) => {setBotNameToCancel(e.target.value)}} value={botNameToCancel}required />
                    </div>
                  </div>
                </div>
                {/* Modal footer */}
                <div className="px-5 py-4 border-t border-gray-200">
                  <div className="flex flex-wrap justify-end space-x-2">
                    <button className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600" onClick={(e) => { e.stopPropagation(); setCancelBotModalOpen(false); }}>Cancel</button>
                    <button className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white" onClick={() => {setBotRunning(true); runBotCanceller()}}>
                      {botRunning ? <svg className="animate-spin w-4 h-4 fill-current flex-shrink-0" viewBox="0 0 16 16">
                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                      </svg> : ''}
                      <span className="ml-2">Cancel Bot</span>
                    </button>
                  </div>
                </div>
              </ModalBasic>
              {/* End */}
            </div>

            {/* Cards */} 
            <div className="grid grid-cols-12 gap-6">
              {
                activeBots.map(bot => {
                  return (
                    <CampaignsCard
                      key={bot.Id}
                      id={bot.Id}
                      category={bot.active ? '2' : '1'}
                      members={[
                        {
                          name: 'Pelham',
                          image: Pelham,
                          link: '#0'
                        },
                      ]}
                      title={bot.name}
                      // link={'test'}
                      content={"User: " + bot.creator + "; Collection (size): " + bot.collection + " (" + bot.collectionSize + "); Offer Price: " + bot.offerPrice + "; Buy Now Only: " + bot.buynowonly + "; Relist Price: " + (bot.relistPrice === '' ? 'none' : bot.relistPrice) + "; Floor Protection: " + bot.floorProtection}
                      dates={
                        { from: 'Expiration Time: ',
                          to: new Date(parseInt(bot.expTime)).toString()
                        }}
                      type={''}
                    />
                  )
                })
              }
            </div>

            {/* Pagination */}
            {/* <div className="mt-8">
              <PaginationNumeric />
            </div> */}

          </div>
        </main>

      </div>
      
    </div>
  );
}

export default Bots;